import { ChooseWorkshopPage } from "./pages/ChooseWorkshopPage";
import { Footer } from "./components/Layout/Footer";
import { useState , useCallback } from "react";
import { Header } from "./components/Layout/Header";
import { BookDatePage } from "./pages/BookDatePage";
import {BookOptionsPage} from "./pages/BookOptionsPage";
import { PersonalDataPage } from "./pages/PersonalDataPage";
import { SummaryPage } from "./pages/SummaryPage";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { FormContext } from "./hooks/context/FormContext ";
//import { VerificationPage } from "./pages/VerificationPage";
import { SuccessPage } from "./pages/SuccessPage";
import { useStep } from "./hooks/context/StepContext";
import { usePrice } from "./hooks/context/PriceContext";
import { AllWorkshopsPage } from "./pages/Additional/AllWorkshopsPage";

//analytics
import ReactGA from 'react-ga4';
import MouseflowScript from "./hooks/MouseflowScript";


ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
  debug_mode: true
});






function App() {

  ReactGA.send("pageview"); 


  const { selectedOptionPrice } = usePrice();
  const location = useLocation();

  const { formData } = useContext(FormContext);
  const {currentStep, nextStep, totalSteps, getWorkshop} = useStep();


  const [chosenDate, setChosenDate] = useState(null);
  const [chosenWorkshopDuration, setChosenWorkshopDuration] = useState(null);
  const chosenWorkshop = getWorkshop();







  // get selected workshop duration from local storage
  useEffect(() => {
    const handleStorageChange = () => {
      const savedWorkshopDuration = JSON.parse(localStorage.getItem("selectedWorkshopDuration"));
      console.log("Retrieved from local storage:", savedWorkshopDuration);
      if (savedWorkshopDuration) {
        setChosenWorkshopDuration(savedWorkshopDuration);
      }
    };
    window.addEventListener('local-storage-changed', handleStorageChange);
    handleStorageChange();

    return () => {
      window.removeEventListener('local-storage-changed', handleStorageChange);
    };
  }, []);

  // get selected date from local storage

  useEffect(() => {
    const handleDateChange = () => {
      const savedDate = JSON.parse(localStorage.getItem("selectedDate"));
      console.log("Retrieved from local storage:", savedDate);
      if (savedDate) {
        setChosenDate(savedDate);
      } else {
        setChosenDate(null);
      }
    };
    window.addEventListener('local-date-changed', handleDateChange);
    handleDateChange();

    return () => {
      window.removeEventListener('local-date-changed', handleDateChange);
    };
  }, []);





  

  const checkNextStep = useCallback(() => {

    if (!chosenWorkshopDuration) {
      alert("Please select a workshop duration before proceeding."); 
      return;
    }

    if (currentStep === 2 && !chosenDate) {
      alert("Please select a date before proceeding.");
      return;
    }

    if (currentStep === 3 && !selectedOptionPrice) {
      alert("Please select an option before proceeding.");
      return;
    }

    if (currentStep === 4) {
      const { fullName, email, phoneNumber, schoolName, agreeToTerms } = formData;

      if (!fullName || !email || !phoneNumber || !schoolName || !agreeToTerms) {
        alert("Please fill in all required fields in the form.");
        return;
      }
    }


    if (currentStep < totalSteps) {
      nextStep();
    }
  }, [chosenWorkshopDuration, chosenDate, selectedOptionPrice , formData, currentStep, totalSteps, nextStep]);




    //cheapest workshop navigation 
    const [calculateCheapest, setCalculateCheapest] = useState(false);
    useEffect(() => {
      const handleCheapestWorkshopSelected = () => {  
        if (currentStep === 1) {
          setCalculateCheapest(true);
          nextStep();
        }
      };
    
      window.addEventListener('nextStep', handleCheapestWorkshopSelected);
    
      return () => {
        window.removeEventListener('nextStep', handleCheapestWorkshopSelected);
      };
    }, [currentStep , nextStep]);
  


    const isDefaultRoute = location.pathname === "/";


  return (
   
    <div className="App">
    <MouseflowScript />
      { !isDefaultRoute && <Header subtotal={selectedOptionPrice} chosenWorkShop={chosenWorkshop} chosenDate={chosenDate} />}
        <Routes>
              <Route path="/" element={<AllWorkshopsPage />} />
              <Route path="/:workshopName/choose-workshop" element={<ChooseWorkshopPage />} />
              <Route path="/:workshopName/book-date" element={<BookDatePage calculateCheapest={calculateCheapest} />} />
              <Route path="/:workshopName/book-options" element={<BookOptionsPage />} />
              <Route path="/:workshopName/personal-data" element={<PersonalDataPage />} />
              <Route path="/:workshopName/summary" element={<SummaryPage />} />
              <Route path="/:workshopName/success" element={<SuccessPage />} />
            {/*<Route path="/verification" element={ <VerificationPage/>}/>*/}
          </Routes>
          { !isDefaultRoute && <Footer onNextStep={checkNextStep} />}
    </div>
   
  );
}

export default App;



import React from 'react';
import {useStep} from '../../hooks/context/StepContext';

export const WorkshopGrid = ({ workshops }) => {


  const { setWorkshop } = useStep();

  const handleWorkshopClick = (workshop) => {
    const workshopSlug = workshop.name.replace(/ /g, '-').toLowerCase();
    console.log("workshop", workshop);
    setWorkshop(workshopSlug);
  }


  return (
    <div>
      <div className="workshop-grid">
        {workshops.map(workshop => (
          <div className="workshop-grid__item" key={workshop.id} onClick={() => handleWorkshopClick(workshop)}>
            <img className="workshop-grid__item-image" src={workshop.img} alt={workshop.name} />
            <h4 className="workshop-grid__item-name">{workshop.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}

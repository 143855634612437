import React from "react";
import { useContext } from "react";
import { FormContext } from "../../hooks/context/FormContext ";
import { useStep } from "../../hooks/context/StepContext";


export const Footer = ({  onNextStep }) => {


    const { handleFormSubmit } = useContext(FormContext);

    const {totalSteps, currentStep, prevStep} = useStep();


    
 
    if (currentStep === 6) {
        return null;
    }

    return (
        <footer>
            <div className="footer">
                {currentStep > 1 && (
                    <button className="footer__previous-step-button" onClick={prevStep}>
                        Previous step
                    </button>
                )}
                <div className="footer__step-text">Step {currentStep} Of {totalSteps}</div>
                {currentStep === 5 ? (
                    <button className="footer__next-step-button" onClick={handleFormSubmit}>
                        Submit booking
                    </button>
                ) : (
                    <button className="footer__next-step-button" onClick={onNextStep}>
                        Next step
                    </button>
                )}
            </div>
        </footer>
    );
}

import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';

const PriceContext = createContext();

export const usePrice = () => useContext(PriceContext);

export const PriceProvider = ({ children }) => {
  //const DISCOUNT = 20;

  //calendar price 
//  damagewaiver 
//selectedoption price

const [calendarPrice, setCalendarPrice] = useState(() => JSON.parse(localStorage.getItem("calendarPrice")) || 0);
  const [damageWaiver, setDamageWaiver] = useState(() => JSON.parse(localStorage.getItem("damageWaiver")) || { isSelected: false });
  const [selectedPrice, setSelectedPrice] = useState(() => JSON.parse(localStorage.getItem("selectedPrice")) || 0);
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(() => JSON.parse(localStorage.getItem("selectedOptionPrice")) || 0);



useEffect(() => {
  localStorage.setItem("calendarPrice", JSON.stringify(calendarPrice));
  localStorage.setItem("selectedPrice", JSON.stringify(selectedPrice));
  localStorage.setItem("damageWaiver", JSON.stringify(damageWaiver));
  localStorage.setItem("selectedOptionPrice", JSON.stringify(selectedOptionPrice));
  window.dispatchEvent(new Event('local-option-changed'));
}, [calendarPrice, selectedPrice, damageWaiver, selectedOptionPrice]);


const handleCalendarPrice = (price) => {
  setCalendarPrice(price);
}


const calculateTotal = useCallback(() => {
  const damageWaiverFee = damageWaiver.isSelected ? parseFloat((selectedPrice * 0.07).toFixed(2)) : 0;
  const total = parseFloat((selectedPrice + damageWaiverFee).toFixed(2));
  return total;
}, [selectedPrice, damageWaiver.isSelected]);


useEffect(() => {
  setSelectedOptionPrice(calculateTotal());
}, [selectedPrice, damageWaiver.isSelected, calculateTotal]);


const resetPrices = () => {
  setSelectedPrice(0);
  setSelectedOptionPrice(0);
  setDamageWaiver({ isSelected: false });
}

  const handleOptionChange = (price) => {
    setSelectedPrice(price);
  };
  

  const handleDamageWaiverChange = (price) => {

    if(selectedPrice !== price) {
      setSelectedPrice(price);
      return;
    }

    setDamageWaiver((prevState) => ({
      isSelected: !prevState.isSelected,
    }));
  };

  return (
    <PriceContext.Provider value={{ handleCalendarPrice	, resetPrices, calendarPrice, selectedPrice, selectedOptionPrice, handleOptionChange, handleDamageWaiverChange, damageWaiver }}>
      {children}
    </PriceContext.Provider>
  );
};

export default PriceContext;

import React, { useEffect, useState } from 'react';
import { collection, getDocs as getDocks } from 'firebase/firestore';
import firebase from '../firebase/firebase';
import { usePrice } from '../hooks/context/PriceContext';
import { parse } from 'date-fns';

export const Calendar = ({calculateCheapest}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [hasFoundCheapest, setHasFoundCheapest] = useState(false);

  const[hasFetched, setHasFetched] = useState(false);
  const[workshopDates, setWorkshopDates] = useState([]);

  
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const dayOfWeek = new Date(year, month, 1).getDay();
    return (dayOfWeek === 0) ? 6 : dayOfWeek - 1;
  };
  




 
// get workshop dates from firestore

  

  useEffect(() => {
    const selectedWorkshop = localStorage.getItem('selectedWorkshopName');
    const selectedDuration = localStorage.getItem('selectedWorkshopDuration');

    if(selectedWorkshop){


      const fetchData = async () => {

        try{

        const cleanedDuration = selectedDuration.replace(/"/g, '');   
        const fullWorkshopName = `${selectedWorkshop} ${cleanedDuration}`;
        const workshopPath = `/courses/${fullWorkshopName}/dates`;
       
        const querySnapshot = await getDocks(collection(firebase.db, workshopPath));
        const dates = querySnapshot.docs.map(doc =>{

          const data = doc.data();
        
          return {
            date: parse(data.Date, 'yyyy-MM-dd', new Date()),
            price: data.Price

          };
        
        });
        
        setWorkshopDates(dates);
        setHasFetched(true);

      }catch(error){
        console.error("Error fetching workshop dates:", error);
      }
    };

    fetchData();

    }

  }, [currentMonth, currentYear]);



  // find the closest available  date to today
  useEffect(() => {
    if (hasFetched && workshopDates.length > 0) {
      const today = new Date();
      const closestDate = workshopDates.filter(date => date.date >= today)
        .reduce((a, b) => a.date < b.date ? a : b, { date: new Date(8640000000000000) }).date;

      if (closestDate.getTime() !== new Date(8640000000000000).getTime()) {
        setCurrentMonth(closestDate.getMonth());
        setCurrentYear(closestDate.getFullYear());
      }
    }
  }, [hasFetched]);


  // calculate the cheapest workshop if needed
  useEffect(() => {

    const findCheapestPrice = () => {
  
        const cheapestWorkshop = workshopDates.reduce((cheapest, current) => {
          return current.price < cheapest.price ? current : cheapest;
        }, workshopDates[0]);
  
        const cheapestDate = cheapestWorkshop.date;
        
        setCurrentMonth(cheapestDate.getMonth());
        setCurrentYear(cheapestDate.getFullYear());

        const priceValue = parseFloat(cheapestWorkshop.price.replace('£', ''));
      
        selectDateAndPerformAction(cheapestDate, priceValue);
      
    };

   
    if(calculateCheapest && workshopDates.length > 0 && !hasFoundCheapest) {
      findCheapestPrice();
      setHasFoundCheapest(true);
    }


  }, [workshopDates, calculateCheapest, hasFoundCheapest]);





  const generateCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);

    const today = new Date();
  
    const daysArray = Array.from({ length: daysInMonth + firstDayOfMonth }, (_, i) => {
      if (i < firstDayOfMonth) {
        // Fill initial slots with empty values or placeholders
        return { isEmpty: true };
      } else {

        const date = new Date(currentYear, currentMonth, i - firstDayOfMonth + 1);
        const dayOfWeek = date.getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        const isPast = date < today;
        const isAvailable = !isWeekend && !isPast;

        const workshopDate = workshopDates.find(workshopDate => 
          workshopDate.date.toDateString() === date.toDateString()
        );


        // Check if the date is between 22 December and 4 January
        const isHolidayPeriod = (date.getMonth() === 11 && date.getDate() >= 22) || (date.getMonth() === 0 && date.getDate() <= 4);
        const price = isHolidayPeriod ? 'Holiday' : (workshopDate ? `£${workshopDate.price}` : 'Unavailable');


        return {
          date: i - firstDayOfMonth + 1,
          month: currentMonth,
          price: price,
          isCurrentMonth: true,
          isAvailable: isAvailable && !!workshopDate && !isHolidayPeriod
        };
      }
    });
  
    return daysArray;
  };
  

  const renderCalendarDays = () => {
    const daysArray = generateCalendarDays();
    return daysArray.map((day, index) => {
      if (day.isEmpty) {
        return <div key={index} className="calendar__day--empty"></div>;
      } else {
        return (
          <Day
            key={index}
            day={day}
            price={day.price} 
            onDayClick={() => handleDayClick(day)}
            isSelected={isDaySelected(day)} />
        );
      }
    });
  };
  
  //navigation

  const hasAvailableDates = (year, month) => {
    return workshopDates.some(date => date.date.getFullYear() === year && date.date.getMonth() === month && date.date >= new Date());
  };

  const goToPreviousMonth = () => {
    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    console.log("Navigating to previous month/year:", prevMonth, prevYear);

    if (hasAvailableDates(prevYear, prevMonth)) {
      setCurrentMonth(prevMonth);
      setCurrentYear(prevYear);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    console.log("Navigating to next month/year:", nextMonth, nextYear);

    console.log("Checking available dates for next month/year:", nextMonth, nextYear);
    const result = hasAvailableDates(nextYear, nextMonth);
    console.log("Available dates:", result);
    if (hasAvailableDates(nextYear, nextMonth)) {
      setCurrentMonth(nextMonth);
      console.log(currentMonth);
      setCurrentYear(nextYear);
    }
  };

  const canGoToPreviousMonth = () => {
    // const today = new Date();
    const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  
    // // Prevent navigating to past months (before today)
    // if (prevYear < today.getFullYear() || (prevYear === today.getFullYear() && prevMonth < today.getMonth())) {
    //   return false;
    // }
  
    // Only allow navigating if there are available dates
    return hasAvailableDates(prevYear, prevMonth);
  };

  const canGoToNextMonth = () => {
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    return hasAvailableDates(nextYear, nextMonth);
  };



  const getMonthName = (monthIndex) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    return monthNames[monthIndex];
  };

  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;

  //days of week

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const renderDaysOfWeek = () => {
    return (
      <div className="calendar__days-of-week">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="calendar__days-of-week--day">{day}</div>
        ))}
      </div>
    );
  };

  
  //Select a date
  const [selectedDate, setSelectedDate] = useState(null);


//check in local storage if there is already a selected date
useEffect(() => {
  const storedDateStr = localStorage.getItem('selectedDate');
  if (storedDateStr) {
      const storedDate = new Date(JSON.parse(storedDateStr));
      setSelectedDate(storedDate);
  }

}, []);


  useEffect(() => {
    if(selectedDate){
      const formattedDate = `${getMonthName(selectedDate.getMonth())} ${String(selectedDate.getDate()).padStart(2, '0')} ${selectedDate.getFullYear()}`;
      localStorage.setItem('selectedDate', JSON.stringify(formattedDate));
      //console.log("Saved to local storage:", formattedDate);
      window.dispatchEvent(new Event('local-date-changed'));
    } else {
      localStorage.removeItem('selectedDate');
      //console.log("Removed from local storage");
    }
  }, [selectedDate]);
  

  const isDaySelected = (day) => {
    return selectedDate && selectedDate.getDate() === day.date &&
           selectedDate.getMonth() === currentMonth &&
           selectedDate.getFullYear() === currentYear;
  };


  const {handleCalendarPrice, resetPrices} = usePrice();


  const selectDateAndPerformAction = (date, price) => {
    setSelectedDate(date);
    resetPrices();
    handleCalendarPrice(price);
    window.dispatchEvent(new Event('local-option-changed'));
  };

  
  const handleDayClick = (day) => {

    const selectedDate = new Date(currentYear, currentMonth, day.date, 12);
    const priceValue = parseFloat(day.price.replace('£', ''));
    selectDateAndPerformAction(selectedDate, priceValue);
    localStorage.setItem('calendarPrice', JSON.stringify(priceValue));
 

  };


                      

  return (
    <div className="calendar">
      <div className="calendar__header">
          <button
              onClick={goToPreviousMonth}
              className={`calendar__header__button ${canGoToPreviousMonth() ? 'enabled' : 'disabled'}`}
              disabled={!canGoToPreviousMonth()}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M3.2755 12.6722C2.90817 12.3009 2.90817 11.6991 3.2755 11.3278L9.26132 5.27842C9.6287 4.90719 10.2243 4.90719 10.5916 5.27842C10.9589 5.64964 10.9589 6.2515 10.5916 6.62274L5.27082 12L10.5916 17.3772C10.9589 17.7484 10.9589 18.3503 10.5916 18.7216C10.2243 19.0928 9.6287 19.0928 9.26132 18.7216L3.2755 12.6722ZM22 12.9506H3.94057V11.0494H22L22 12.9506Z"/>
            </svg>
          </button>
            <span className='calendar__header__month'>{getMonthName(previousMonth)}</span>
            <span className='calendar__header__month-year'>
                  {getMonthName(currentMonth)}
                  <span className="calendar__header__year">{currentYear}</span>
            </span>
            <span className='calendar__header__month'>{getMonthName(nextMonth)}</span>
          <button
          onClick={goToNextMonth}
          className={`calendar__header__button ${canGoToNextMonth() ? 'enabled' : 'disabled'}`}
          disabled={!canGoToNextMonth()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20.7245 12.6722C21.0918 12.3009 21.0918 11.6991 20.7245 11.3278L14.7387 5.27842C14.3713 4.90719 13.7757 4.90719 13.4084 5.27842C13.0411 5.64964 13.0411 6.2515 13.4084 6.62274L18.7292 12L13.4084 17.3772C13.0411 17.7484 13.0411 18.3503 13.4084 18.7216C13.7757 19.0928 14.3713 19.0928 14.7387 18.7216L20.7245 12.6722ZM2 12.9506H20.0594V11.0494H2L2 12.9506Z"/>
            </svg>
          </button>
      </div>
      {renderDaysOfWeek()}
      <div className="calendar__grid">
        {renderCalendarDays()}
      </div>
    </div>
  );
};

const Day = ({ day, price, onDayClick, isSelected  }) => {

  let dayClass = isSelected ? "day selected" : "day";
  dayClass += day.isAvailable ? "" : " unavailable";

  const handleClick = () => {
    if (day.isAvailable) {
      onDayClick();
    }
  };


  return (
    <div className={dayClass} onClick={handleClick}>
      <div className="day__date">{day.date}</div>
      <div className="day__price">{price}</div>
    </div>
  );
};

import React from "react";
import { Heading } from "../components/Heading";
import { useNavigate } from "react-router-dom";
import { useStep } from "../hooks/context/StepContext";
import { usePrice } from "../hooks/context/PriceContext";







export const SuccessPage = () => {

    const navigate = useNavigate();

    const {resetStep} = useStep();


    const {resetPrices} = usePrice();


    //redirect to AllWorkshops and set steps count to 1 and localstorage to clear 

    const handleClick = () => {
        localStorage.clear();
        resetStep();
        resetPrices();
        navigate('/');

    }




    return (
        <div className="success-page">
            <Heading text={"Booking Confirmed"} subtext={"Thank you for booking with us. Your booking is confirmed. You will receive an email with your booking details shortly."}/>
            <button onClick={handleClick} className="success-page__btn">Book Another Workshop</button> 
        </div>
    );
}
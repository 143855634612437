import React from "react";
import { Heading } from "../components/Heading";
import { WorkshopList } from "../components/Layout/WorkshopList";






export const ChooseWorkshopPage = () => {

    



    return (
        
        <div className="choose-workshop-page">
        <Heading className="choose-workshop-page__heading" text={`Choose Duration`}/>
        <WorkshopList className="choose-workshop-page__workshop-list"/>
      </div>
    );
}

import React from 'react';

export const CategoryGrid = ({ categories, onSelect }) => {
  return (
    <div className="category-grid">
      {categories.map(category => (
        <div key={category.id} className="category-grid__item" onClick={() => onSelect(category)}>
          <img className="category-grid__image" src={category.img} alt={category.name} />
          <h3 className="category-grid__name">{category.name}</h3>
        </div>
      ))}
    </div>
  );
}

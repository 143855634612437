import React from "react";
import { Heading } from "../components/Heading";
import { BookOption } from "../components/BookOption";
import { usePrice } from "../hooks/context/PriceContext";

export const BookOptionsPage = () => {
  const DISCOUNT = 20;
  
  
  const { calendarPrice, selectedPrice, handleOptionChange, handleDamageWaiverChange, damageWaiver } = usePrice();


  const discountedPrice = calendarPrice - DISCOUNT;

  

 
const text = "Our price includes travel costs to all areas in England excluding postcodes in the South West, East Anglia and North East. These specific postcodes and travel costs are available upon request.";
 
 




  return (
      <div className="book-options-page">
        <Heading text={'Booking Type'} subtext={'We are able to offer a discount on confirmed bookings. Please choose your preferred option.'} />
        <div className="book-options-page-wrapper">
              <BookOption 
                  price={calendarPrice}
                  priceText={"Standard Price:"} 
                  isSelected={selectedPrice === calendarPrice}
                  isDamageWaiverSelected={damageWaiver.isSelected && selectedPrice === calendarPrice}
                  onOptionChange={() => handleOptionChange(calendarPrice)}
                  onDamageWaiverChange={handleDamageWaiverChange}

              />
              <BookOption 
                  price={discountedPrice}
                  priceText={"Discounted Price:"} 
                  discount={20}
                  isSelected={selectedPrice === discountedPrice}
                  isDamageWaiverSelected={damageWaiver.isSelected && selectedPrice === discountedPrice}
                  onOptionChange={() => handleOptionChange(discountedPrice)}
                  onDamageWaiverChange={handleDamageWaiverChange} 
              />
        </div>
          <div className="booking-info">
          <p>
            {text}
          </p>
        </div>
      </div>
  );
}

import React from "react";
import { Heading } from "../components/Heading";
import { Calendar } from "../components/Calendar";

export const BookDatePage = ({calculateCheapest}) => {

    return (
        
        <div className="book-date-page">
            <Heading text={'Book Date'} />
            <div className="book-date-page__calendar">
                <Calendar calculateCheapest= {calculateCheapest}/>
            </div>
        </div>
    );
}
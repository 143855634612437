import React from "react";





export const WorkshopItem = ({ name, desc, isSelected, onWorkshopSelect }) => {
    
    //const workshopItemClasses = `workshop-item__image ${color === 'blue' ? 'blue' : color === 'red' ? 'red' : ''}`;

    const selectedClass = isSelected ? "workshop-item--selected" : "workshop-item";

    const inputId = `workshop-selection-${name}`;


    const handleChange = () => {
        if (onWorkshopSelect) {
            onWorkshopSelect();
        }
    }

    return (
        <label htmlFor={inputId} className="workshop-label">
        <div className={selectedClass}>
            
            <div className='workshop-item__heading'>
                <input className="workshop-item__heading--radio" type="radio" name="workshopSelection" onChange={handleChange} checked={isSelected}  id={inputId}></input>
                <h3 className="workshop-item__heading--name">{name}</h3>
            </div>

            <p className="workshop-item__description">{desc}</p>
            {/* <div className={workshopItemClasses}>
                <img className="workshop-item__image-img" src={img} alt="workshop"></img>
            </div> */}
        </div>
        </label>
    );
}

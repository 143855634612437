import React from "react";
import { Summary } from "../components/Summary";


export const SummaryPage = () => {
    
        return (
            <div className="summary-page">
               <Summary/>
            </div>
        );
    }


    
import { useEffect } from 'react';

const MouseflowScript = () => {
  useEffect(() => {
    // Create the script element
    const mfScript = document.createElement('script');
    mfScript.type = 'text/javascript';
    mfScript.defer = true;
    mfScript.src = '//cdn.mouseflow.com/projects/ed427f45-186d-4c34-9e8a-49e18718a311.js';

    // Append the script to the head
    document.getElementsByTagName('head')[0].appendChild(mfScript);

    // Initialize the Mouseflow queue
    window._mfq = window._mfq || [];

    // Cleanup script on component unmount
    return () => {
      document.getElementsByTagName('head')[0].removeChild(mfScript);
    };
  }, []);

  return null; // This component doesn't render anything visible
};

export default MouseflowScript;

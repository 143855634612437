import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const StepContext = createContext();

export const useStep = () => useContext(StepContext);

export const StepProvider = ({ children }) => {

 //utility 
 const formatWorkshopName = (name) => {
  // Check if 'name' is not a string
  if (typeof name !== 'string') {
    console.error('Invalid input for workshop name:', name);
    return ''; // Return an empty string or handle this case as needed
  }

  const specialCases = {
    'vr': 'VR',
    'eco': 'ECO',
    'ww1' : 'WW1',
    'ww2' : 'WW2',
    'ww1-women' : 'WW1',
    'ww2-women ' : 'WW2',
  };

  return name
    .replace(/-/g, ' ') 
    .split(' ') 
    .map((word, index) => {
      if (specialCases[word.toLowerCase()]) {
        return specialCases[word.toLowerCase()];
      }
     
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' '); 
};



const decodeUrl = (url) => {
  try {
    return decodeURIComponent(url.replace(/-/g, ' '));
  } catch (e) {
    console.error('Failed to decode URL:', url, e);
    return url; // Return the original URL in case of an error
  }
}

/// end of utility


const [workshopUrl, setWorkshopUrl] = useState('');

const [currentStep, setCurrentStep] = useState();

  const totalSteps = 5;
  const navigate = useNavigate();
  const location = useLocation();  
  

 
  



  
  useEffect(() => {

    if(!workshopUrl){

    const workshopRegex = /\/([^/]+)\/choose-workshop$/; 
    const match = location.pathname.match(workshopRegex);
    const initialWorkshopUrl = match ? match[1] : '';
    setWorkshopUrl(initialWorkshopUrl);
    const initialWorkshop = match ? formatWorkshopName(match[1]) : '';
    setWorkshopName(initialWorkshop);
    const initialStep = initialWorkshop ? 1 : 69;
    setCurrentStep(() => Number(sessionStorage.getItem("currentStep")) || initialStep);
    if (initialWorkshop) {
      localStorage.setItem("selectedWorkshopName", initialWorkshop);
    }
  }
}, [location.pathname, workshopUrl]); // Depend on location.pathname to re-run this effect when the URL changes


 
  const [workshopName, setWorkshopName] = useState('');

  useEffect(() => {
    localStorage.setItem("selectedWorkshopName", workshopName);
  } , [workshopName]);





  const getRouteForStep = useCallback((step, workshop) => {
    const baseRoute = workshop ? `/${workshop}` : '';
    // Map the step number to a route
    switch (step) {
      case 1: return `${baseRoute}/choose-workshop`;
      case 2: return `${baseRoute}/book-date`;
      case 3: return `${baseRoute}/book-options`;
      case 4: return `${baseRoute}/personal-data`;
      case 5: return `${baseRoute}/summary`;
      case 6: return `${baseRoute}/success`;
      case 69: return "/";
      default: return "/";
    }
  }, []);


  useEffect(() => {
    sessionStorage.setItem("currentStep", currentStep);
    navigate(getRouteForStep(currentStep, workshopUrl));
  }, [currentStep, navigate, getRouteForStep, workshopUrl]);

  // Function to go to the next step
  const nextStep = useCallback(() => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      navigate(getRouteForStep(currentStep + 1));
    }
  }, [currentStep, totalSteps, navigate, getRouteForStep]);

  // Function to go to the previous step
  const prevStep = useCallback(() => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      navigate(getRouteForStep(currentStep - 1));
    }
  }, [currentStep, navigate, getRouteForStep]);

  // Function to directly set a specific step
  const setStep = useCallback((step) => {
    setCurrentStep(step);
  }, []);

  const successStep = useCallback(() => {
    setCurrentStep(6);
  }, []);

  const resetStep = useCallback(() => {
    console.log("resetting step");
    setCurrentStep(69);
  }, []);

  const setWorkshop = useCallback((workshop) => {
    const formattedName = formatWorkshopName(workshop);
    setWorkshopUrl(workshop);
    setWorkshopName(formattedName);
    setCurrentStep(1);
  }, []);

  const getWorkshop = useCallback(() => {
    return workshopName;
  }, [workshopName]);




 

  return (
    <StepContext.Provider value={{ currentStep, nextStep, prevStep, setStep, totalSteps, successStep, resetStep, setWorkshop, getWorkshop }}>
      {children}
    </StepContext.Provider>
  );
};

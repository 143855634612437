import React, { useEffect } from "react";
import { useState } from "react";
import { WorkshopItem } from "../WorkshopItem";
import { useStep } from "../../hooks/context/StepContext";



export const WorkshopList = () => {
  

  const [selectedWorkshopDuration, setSelectedWorkshopDuration] = useState(null);

  const {getWorkshop} = useStep();


  const workshopName = getWorkshop();

const handleCalculateCheapestDay = () => {
  if(selectedWorkshopDuration){
      
  const next  = new CustomEvent('nextStep');
  window.dispatchEvent(next);

  }
};


useEffect(() => {
  const storedWorkshopDuration = JSON.parse(localStorage.getItem("selectedWorkshopDuration"));
  if (storedWorkshopDuration) {
    setSelectedWorkshopDuration(storedWorkshopDuration);
  }
}, []);


useEffect(() => {
  if (selectedWorkshopDuration) {
    localStorage.setItem("selectedWorkshopDuration", JSON.stringify(selectedWorkshopDuration));
    window.dispatchEvent(new Event('local-storage-changed'));
  } 
}, [selectedWorkshopDuration]);

const handleWorkshopSelect = (workshop) => {
  setSelectedWorkshopDuration(workshop.duration); 
  
};

const workshops = [
  { name: `${workshopName} Full Day`, duration: "Full", desc: "Full day workshop"},
  { name: `${workshopName} Half Day`, duration: "Half", desc: "Half day workshop"}
]
  
  return (


    <div className="workshop-list">
      {workshops.map((workshop, index) => (
                <WorkshopItem
                    key={index}
                    name={workshop.name}
                    desc={workshop.desc}
                    isSelected={workshop.duration === selectedWorkshopDuration}
                    onWorkshopSelect={() => handleWorkshopSelect(workshop)}
                />
        ))}
      <button className="workshop-list__button" onClick={handleCalculateCheapestDay}>
        Show the cheapest workshop
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.51842e-06 11.0101L2.69504e-06 8.9899L16.1212 8.9899L10.5657 3.43434L12 2L20 10L12 18L10.5657 16.5657L16.1212 11.0101L2.51842e-06 11.0101Z"
            fill="#0059FC"
          />
        </svg>
      </button>
    </div>
  );
};


import React from "react";


export const Heading = ({ text, subtext }) => {
    return (
        <div className="heading">
            <h2 className="heading__text">{text}</h2>
            <p className="heading__subtext">{subtext}</p>
        </div>
    );
};

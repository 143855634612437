import React from "react";
import { Heading } from "../components/Heading";
import { Form } from "../components/Form";

export const PersonalDataPage = () => {

    return (
        <div className="personal-data-page">
            <Heading text={"Complete Personal Details"} subtext={"Please complete your personal details."} />
            <Form/>
        </div>
    );
}
import React, {useState, useContext} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormContext } from "../hooks/context/FormContext ";

export const Form = () => {

  
  const { formData, setFormData, handleFormSubmit } = useContext(FormContext);

      const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevFormData => ({
          ...prevFormData,
          [name]: type === 'checkbox' ? checked : value
        }));
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        handleFormSubmit();
      };

   

      const [phoneNumber, setPhoneNumber] = useState('');
      const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);


      const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        setIsPhoneNumberValid(validatePhoneNumber(value));


        setFormData(prevFormData => ({
          ...prevFormData,
          phoneNumber: value
        }));
      }

      const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^\d{3}\d{2}\d{2}\d{2}$/;
        return phoneNumberRegex.test(phoneNumber);
      }
        
            return (
                <form onSubmit={handleFormSubmit} className="registration-form" id="registration-form">
                <label htmlFor="fullName" className="registration-form__label">Full name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Enter your full name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  className="registration-form__input"
                />
                
                <label htmlFor="email" className="registration-form__label">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="registration-form__input"
                />
                
                <label htmlFor="phoneNumber" className="registration-form__label">Phone number</label>
                <PhoneInput
                  country={'gb'}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className="registration-form__input--tel"
                  inputProps={{
                    required: true,
                  }}
                />
                
                <label htmlFor="schoolName" className="registration-form__label">School name</label>
                <input
                  type="text"
                  id="schoolName"
                  name="schoolName"
                  placeholder="Enter your School name"
                  value={formData.schoolName}
                  onChange={handleInputChange}
                  className="registration-form__input"
                />

                <label htmlFor="schoolPostcode" className="registration-form__label">School postcode</label>
                  <input
                      type="text"
                      id="schoolPostcode"
                      name="schoolPostcode"
                      placeholder="Enter your school postcode"
                      value={formData.schoolPostcode} 
                      onChange={handleInputChange}
                      className="registration-form__input"
                />

                <label htmlFor="agreeToTerms" className="registration-form__label--checkbox">
                  <input
                    type="checkbox"
                    id="agreeToTerms"
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={handleInputChange}
                    className="registration-form__input--checkbox"
                  />
                  I agree to the <a href="https://www.educationgroup.co.uk/workshop-terms" target="_blank" rel="noopener noreferrer"  className="registration-form__input--terms">Terms and Conditions</a> and <a href="https://www.educationgroup.co.uk/website-privacy-notice" target="_blank" rel="noopener noreferrer" className="registration-form__input--terms">Privacy Policy</a>
                  .
                </label>

                <button type="submit" className="registration-form__button">Submit</button>

                </form>
            );
        }
 import React from "react";
import { useState, useEffect } from "react";
import { CategoryGrid } from "../../components/Additional/CategoryGrid";
import { WorkshopGrid } from "../../components/Additional/WorkshopGrid";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import logo from "../../assets/logo.svg";




 export const AllWorkshopsPage = () => {

      const [categories, setCategories] = useState([]);
      const [workshops, setWorkshops] = useState([]);
      const [selectedCategory, setSelectedCategory] = useState(null);


     
  useEffect(() => {
    const fetchCategories = async () => {
      const categorySnapshot = await getDocs(collection(db, "categories"));
      setCategories(categorySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchWorkshops = async () => {
      if (selectedCategory) {
        console.log("Selected Category ID for Query:", selectedCategory.name);
        const workshopsQuery = query(
          collection(db, "workshops"),
          where("category_id", "==", selectedCategory.name)
        );
        const workshopSnapshot = await getDocs(workshopsQuery);
        const workshopsData = workshopSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        console.log("Workshops Data:", workshopsData); 
        setWorkshops(workshopsData);
      }
    };

    fetchWorkshops();
  }, [selectedCategory]);



      const handleCategorySelect = (category) => {
        setSelectedCategory(category);
      };

      const resetSelection = () => {
        setSelectedCategory(null);
      };



        return (
          <div className="container">
            <div className="header-workshops">
              <img src={logo} alt="Logo" onClick={resetSelection}/>
            </div>

            <div className="content">
              <h1 className="title">
                {selectedCategory ? `All ${selectedCategory.name} Workshops` : "Choose Category"}
              </h1>
              {!selectedCategory && (
                <CategoryGrid categories={categories} onSelect={handleCategorySelect} />
              )}
              {selectedCategory && (
                <WorkshopGrid
                  workshops={workshops}
                />
              )}
            </div>
          </div>
        );
    }

